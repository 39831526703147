<template>
  <section v-if="isConsultationOrExam">
    <div class="item">
      <label for="form-select" class="item-title">{{ labelSelect }}</label>
      <Select
        id="form-select"
        class="select-forms"
        :value="selectedForm ? selectedForm.value : null"
        :options="formOptions"
        @change="onChange"
        border="none"
        colorOptions="#E7ECFE"
        :disabled="!canEdit"
      />
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Select:() => import('@/components/Select'),
  },
  computed: {
    ...mapState({
      selectedForm: state => state.attendance.form.selected,
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance
    }),
    isConsultationOrExam() {
      return ['ATTENDANCE', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'].includes(this.attendance?.type)
    },
    labelSelect() {
      return this.attendance?.type === 'ATTENDANCE' ? 'Consulta:' : 'Exame:'
    }
  },
  data() {
    return {
      forms: [],
      formOptions: [
        { label: 'Completa', value: null, type: "ATTENDANCE" }
      ],
      invalidDate: '-000001-11-30T03:06:28.000000Z'
    }
  },
  methods: {
    ...mapActions('attendance/form', ['setAttendanceSelectForm']),
    ...mapActions('attendance/menu', ['removeTriagemMenu', 'addTriagemMenu']),
    async getForms(formId) {
      const professional = this.attendance?.professional?.id ?? JSON.parse(localStorage.getItem('user')).id
      this.$set(this.attendance, 'professional_id', professional)
      const { data } = await this.api.getForms(this.attendance.clinic.id, professional, this.attendance.type)     
      this.forms = data

      if (!this.attendance?.form_id && this.attendance?.type === 'EXAM') {
        const formSelected = data.find(item => item.default && item.active)
        if (formSelected) {
          formId = formSelected?.id
          this.$set(this.attendance, 'form_id', formId)
          await this.updateExamAttendance()
        }
      }
      const options = data
        .filter(form => form.active)
        .map(form => ({ label: form.name, value: form.id, forms: form.forms, type: form.type }))

      if (this.attendance.type === 'ATTENDANCE')
      {
        options.unshift({ label: 'Completa', value: null, type: "ATTENDANCE" })
        if(options.findIndex(option => option.label === 'Prescrição de óculos BV') !== -1) {
          const index = options.findIndex(option => option.label === 'Prescrição de óculos BV')
          options.splice(index, 1)
        }
        if(options.findIndex(option => option.label === 'Benefício Visão') !== -1) {
          const index = options.findIndex(option => option.label === 'Benefício Visão')
          options.splice(index, 1)
        }
      }
    

      this.formOptions = options
      const form = options.find(f => f.value === formId) ?? {}

      if (this.attendance?.attendance_form)
        this.$set(form, 'forms', this.attendance?.attendance_form?.forms)

      if (this.attendance?.type !== 'BV_EXAM') {
        this.removeTriagemMenu()
      }else if(this.attendance?.type === 'BV_EXAM'){
        this.addTriagemMenu()
      }
      this.setAttendanceSelectForm(form)
    },
    async onChange(formId) {
      if (this.selectedForm === formId) return;
      const form = this.formOptions.find(f => f.value === formId)
      const isLoading = this.$loading.show()
      this.setAttendanceSelectForm(form)

      try{
        await this.api.updateAttendanceApi(this.attendance.id, { form_id: form ? form.value : null })
        this.setAttendanceSelectForm(form)
      }catch (error) {
        this.$toast.error(error.message)
      }finally{
        isLoading.hide()
      }
    },
    async updateExamAttendance() {
      try {
        const startDatetime = this.start_datetime && this.moment(this.start_datetime).isValid() && this.moment(this.start_datetime)>=0 ? this.start_datetime : this.moment().format('YYYY-MM-DDTHH:mm:ss.000000Z')
        await this.api.updateExamAttendance(this.attendance.id, {
          start_datetime: startDatetime,
          form_id: this.attendance.form_id,
          professional_id: this.attendance.professional_id
        })
        this.$set(this.attendance, 'start_datetime', startDatetime)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
  watch: {
    attendance: {
      handler: async function (attendance) {
        attendance?.id && await this.getForms(attendance.form_id)
      },
      immediate: true,
    }
  }
}
</script>
<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items:center;
    padding: 16px;
    gap: 20px;
    .item-title {
      font-weight: 600;
      font-size: 16px;
      color: var(--dark-blue);
      margin-bottom: 0px !important;
    }
  }
  .select-forms {
    background: var(--blue-100) !important;
    border-radius: 33px !important;
  }
</style>
